import React from 'react'
import Marco from '../components/marco'
import leoncio from './leoncito.jpg'
import {css, keyframes} from '@emotion/core'
import styled from '@emotion/styled'

// #region CSS
const baseimagen = css`
  background-color: #16455a;
  height: 783px;
  overflow: hidden;
  position:relative;
  @media (max-width: 768px) {
    height: 480px;
    background-color: #16455a;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    height: 375px;
  }
`
const leoncito = css`
  width: 100%;
`
const imagenfondo = css`
  display: flex;
  width: 100%;
  background: url("/img/inicio/leoncito.jpg") center top no-repeat;
  background-size: 100% auto;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
`
const romaitalia = css`
  font-family: "Architects Daughter", cursive;
  font-size: 35px;
  color: white;
  text-shadow: 1px 0 black;
  font-weight: 300;
  text-align: right;
  position: absolute;
  top: 41.7rem;
  right: 2rem;
  @media (max-width: 768px) {
    top: 22rem;
    font-size: 18px;
    padding-left: 2rem;
    right: 1rem;
  }
  @media (max-width: 500px) {
    top: 25rem;
    font-size: 18px;
    padding-left: 2rem;
  }
`
const descripcioninicio = css`
  font-family: "Architects Daughter", cursive;
  font-size: 28px;
  color: white;
  text-shadow: 1px 0 black;
  font-weight: 200;
  text-align: right;
  position: absolute;
  top: 45.7rem;
  right: 2rem;
  @media (max-width: 768px) {
    top: 26rem;
    font-size: 18px;
    padding-left: 2rem;
    right: 1rem;
  }
  @media (max-width: 500px) {
    top: 29rem;
    font-size: 18px;
    padding-left: 2rem;
  }
`
// #endregion


// transform: scale(160%) translate(-18%,18%);
// transform: scale(400%) translate(16%,8%);
const Hip = keyframes`
from, 25%, 50%, to {
  transform: scale(105%) translate(2%,2%);
}
from, 50%, 99%, to {
  transform: scale(100%) translate(0%,0%);
  }
`
const Hop = keyframes`
  from, 25%, 50%, to {
    transform: scale(1.05) translate(2%, 2%);
  }
 from, 50%, 99%, to {
    transform: scale(1) translate(0%,0%);
  }
`
const Paralax = styled.div`
  animation: ${Hip} 20s linear;
  -webkit-animation: ${Hop} 40s linear;
`


const Home = () =>
  <Marco>
    <div css={baseimagen}>
      <Paralax>
        <img css={leoncito} src={leoncio} alt="" />
      </Paralax>
      <div css={imagenfondo}>
        <div css={romaitalia}>
          <span>ROMA . ITALIA</span>
        </div>
      </div>
      <div css={descripcioninicio}>
          Anécdotas, tips. Recorriendo ... Viajando ... De todo un poco. Asesoramiento
      </div>
    </div>
  </Marco>

export default Home
